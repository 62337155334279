@font-face {
    font-family: imfellenglish;
    src: url(../font/IMFeENrm28P.ttf);
}

@font-face {
    font-family: lato;
    src: url(../font/Lato-Light.ttf);
}

@font-face {
    font-family: latobold;
    src: url(../font/Lato-Bold.ttf);
}