footer {
  margin: 40px 0;
}

header {
  display: flex;
  flex-direction: column;
}
header .head-list {
  display: flex;
  margin: auto;
}
header .head-list a {
  text-decoration: none;
}
header .head-list li {
  list-style: none;
  font-family: lato;
  padding: 20px;
  color: white;
  text-decoration: none;
  transition: 0.2s;
}
header .head-list li:hover {
  color: gold;
  transition: 0.2s;
}
header .head-list li:active {
  color: rgb(141, 120, 0);
}

@media (max-width: 480px) {
  header .head-list {
    padding: 15px 0;
  }
}
.home #title {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
  width: 64%;
}
.home #title h1 {
  font-family: imfellenglish;
  font-size: 70px;
  margin-bottom: 0;
}
.home #title h2 {
  font-family: imfellenglish;
  margin: 0;
}

.card-home {
  display: flex;
  flex-direction: column;
  margin: 100px 0;
}
.card-home .wrapper {
  display: flex;
  flex-direction: row;
  width: 66%;
  margin: auto;
}
.card-home .wrapper2 {
  display: flex;
  flex-direction: row-reverse;
  width: 66%;
  margin: auto;
}
.card-home #img {
  margin: auto;
  width: 100%;
}
.card-home #img img {
  width: 100%;
  float: left;
}
.card-home #text {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-home #text #title {
  display: block;
  width: 100%;
}
.card-home #text #content {
  display: flex;
  height: 100%;
  align-items: center;
}
.card-home #text #content p {
  margin: 0px 40px;
  font-family: lato;
  font-size: 20px;
  text-align: left;
}
.card-home #text #content #cont2 {
  text-align: right;
}

@media (max-width: 1024px) {
  .card-home .wrapper {
    width: 90%;
  }
  .card-home .wrapper2 {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .card-home .wrapper {
    flex-direction: column;
    width: 90%;
  }
  .card-home .wrapper2 {
    flex-direction: column;
    width: 90%;
  }
  .card-home #text #content #cont2 {
    text-align: left;
  }
}
.episodes {
  display: block;
}
.episodes .wrap {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
}
.episodes .wrap .pagination {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
.episodes .wrap .pagination button {
  border: none;
  background-color: unset;
  color: rgba(255, 204, 0, 0.76);
  font-family: imfellenglish;
  font-size: 20px;
}
.episodes .wrap .pagination p {
  font-family: imfellenglish;
  font-size: 60px;
  margin: 0;
}
.episodes .wrap .the-episode {
  display: block;
  width: 60%;
  margin: auto;
  padding: 40px 0px;
}
.episodes .wrap .the-episode a {
  color: rgba(255, 204, 0, 0.76);
  text-decoration: none;
  font-family: imfellenglish;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.08em;
  text-transform: none;
  line-height: 1.5em;
  text-align: center;
}
.episodes .wrap .the-episode #date {
  color: rgba(221, 177, 0, 0.76);
  text-decoration: none;
}
.episodes .wrap .the-episode #cont p {
  font-family: Lato;
  font-style: normal;
  font-weight: 100;
  font-size: 19px;
  letter-spacing: 0.075em;
  text-transform: none;
  line-height: 1.8em;
  color: #fff;
}

.episode {
  display: block;
}
.episode article {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: auto;
}
.episode article #title h1 {
  color: rgba(255, 204, 0, 0.76);
  text-decoration: none;
  font-family: imfellenglish;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 0.08em;
  text-transform: none;
  line-height: 1.5em;
  text-align: center;
}
.episode article #date {
  color: rgba(221, 177, 0, 0.76);
  text-decoration: none;
}
.episode .wrap #cont p {
  font-family: Lato;
  font-style: normal;
  font-weight: 100;
  font-size: 19px;
  letter-spacing: 0.075em;
  text-transform: none;
  line-height: 1.8em;
  color: #fff;
}

audio {
  width: 50%;
}

figure {
  display: block ruby;
  margin: 50px 0px;
}

th {
  padding: 20px;
  border: 1px solid white;
}

td {
  padding: 10px;
}

@media (max-width: 1024px) {
  .episodes .wrap .the-episode {
    display: flex;
    justify-content: center;
    margin: unset;
    width: auto;
  }
  .episodes article {
    justify-content: center;
    width: 90%;
  }
  audio {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .episodes .wrap .the-episode {
    display: flex;
    justify-content: center;
    margin: unset;
    width: auto;
  }
  .episodes article {
    justify-content: center;
    width: 90%;
  }
  audio {
    width: 100%;
  }
}
.title {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 20px;
  width: 64%;
}
.title h1 {
  font-family: imfellenglish;
  font-size: 70px;
  margin-bottom: 0;
}
.title h2 {
  font-family: imfellenglish;
  margin: 0;
}

@media (max-width: 480px) {
  .title {
    width: 90%;
  }
  .title h1 {
    font-size: 50px;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4d4040;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  text-align: center;
  color: white;
}
.App .our-mission {
  display: block;
}
.App .our-mission hr {
  border-style: none;
  border-width: 0;
  background-color: #fff;
  height: 1px;
  opacity: 0.2;
  width: 60%;
}
.App .our-mission .wrap {
  display: flex;
  width: 60%;
  margin: 60px auto;
}
.App .our-mission .wrap .left {
  width: 2506px;
}
.App .our-mission .wrap .left h1 {
  margin-top: 0;
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1.25em;
  color: rgba(255, 204, 0, 0.76);
}
.App .our-mission .wrap .left h2 {
  text-align: center;
  white-space: pre-wrap;
  font-family: latobold;
  font-style: normal;
  font-weight: bold;
  font-size: 19px;
  letter-spacing: 0.075em;
  text-transform: none;
  line-height: 1.8em;
  color: #fff;
}
.App .our-mission .wrap .right p {
  margin-top: 0;
  margin: 0 40px;
  text-align: left;
  font-family: lato;
  font-style: normal;
  font-weight: 100;
  font-size: 19px;
  letter-spacing: 0.075em;
  text-transform: none;
  line-height: 1.8em;
  color: #fff;
}
.App .background {
  min-width: 100%;
  width: 100%;
  min-height: 100%;
  z-index: -1;
  position: fixed;
  transform: translate3d(0, 0, 0);
}
.App .background img {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  opacity: 15%;
}

@media (max-width: 480px) {
  .our-mission .wrap {
    flex-direction: column;
    width: 95%;
  }
  .our-mission .wrap .left {
    width: unset;
  }
}
@font-face {
  font-family: imfellenglish;
  src: url(../font/IMFeENrm28P.ttf);
}
@font-face {
  font-family: lato;
  src: url(../font/Lato-Light.ttf);
}
@font-face {
  font-family: latobold;
  src: url(../font/Lato-Bold.ttf);
}

