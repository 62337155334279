header {
    display: flex;
    flex-direction: column;

    .head-list {
        display: flex;
        margin: auto;

        a {
            text-decoration: none;
        }

        li {
            list-style: none;
            font-family: lato;
            padding: 20px;
            color: white;
            text-decoration: none;
            transition: 0.2s;
        }

        li:hover {
            color: gold;
            transition: 0.2s;
        }

        li:active {
            color: rgb(141, 120, 0);
        }
    }
}

@media (max-width: 480px) {
    header .head-list {
        padding: 15px 0;
    }
}
