.title {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 20px;
    width: 64%;

    h1 {
        font-family: imfellenglish;
        font-size: 70px;
        margin-bottom: 0;
    }

    h2 {
        font-family: imfellenglish;
        margin: 0;
    }
}

@media (max-width: 480px) {
    .title {
        width: 90%;

        h1 {
            font-size: 50px;
        }
    }
}
