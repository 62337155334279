.card-home {
    display: flex;
    flex-direction: column;
    margin: 100px 0;

    .wrapper {
        display: flex;
        flex-direction: row;
        width: 66%;
        margin: auto;
    }

    .wrapper2 {
        display: flex;
        flex-direction: row-reverse;
        width: 66%;
        margin: auto;
    }

    #img {
        margin: auto;
        width: 100%;

        img {
            width: 100%;
            float: left;
        }
    }

    #text {
        width: 100%;
        display: flex;
        flex-direction: column;

        #title {
            display: block;
            width: 100%;

            h3 {
            }
        }

        #content {
            display: flex;
            height: 100%;
            align-items: center;

            p {
                margin: 0px 40px;
                font-family: lato;
                font-size: 20px;
                text-align: left;
            }

            #cont2 {
                text-align: right;
            }
        }
    }
}

@media (max-width: 1024px) {
    .card-home {
        .wrapper {
            width: 90%;
        }
        .wrapper2 {
            width: 90%;
        }
    }
}

@media (max-width: 480px) {
    .card-home {
        .wrapper {
            flex-direction: column;
            width: 90%;
        }
        .wrapper2 {
            flex-direction: column;
            width: 90%;
        }
        #text #content #cont2 {
            text-align: left;
        }
    }
}
